import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700 mt-4 mb-6`;
const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImageBefore = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded relative`,
  `
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: 0;
    border-radius: 0.25rem;
  }
`
]);
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded relative`,
  `
`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h4`text-xl mt-2 font-bold`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`text-sm inline-block mx-auto md:mx-0 mt-6`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  subheading = "Our Work",
  headingHtmlComponent = (
    <>
      We've done some <span tw="text-primary-700">amazing jobs.</span>
    </>
  ),
  description = "See the difference MCS can make! From stained carpets to gleaming floors, our floor cleaning and carpet shampooing services will leave your space spotless and inviting.",
  linkText = "Get in touch!",
  textOnLeft = false
}) => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1563461660947-507ef49e9c47?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      service: "Floor polishing",
      title: "Before",
      durationText: "90 Days Campaign",
      locationText: "New York"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1573165231977-3f0e27806045?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      service: "Floor polishing",
      title: "After",
      durationText: "180 Day Campaign",
      locationText: "Palo Alto"
    }
  ];
  return (
    <Container id="work">
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <p className="mt-0 text-primary-700 text-sm"><b>Limited-Time Offer:</b> Enjoy free Floor Polishing or Carpet Shampooing in your second month of service with any annual contract. Book today to take advantage of this exclusive promotion!</p>
              <PrimaryButton buttonRounded={true} as="a" href="tel:+19085405092">
                {linkText}
              </PrimaryButton>
            </HeadingInfoContainer>
          </HeadingColumn>
          <CardColumn>
            <Card>
              <CardImageBefore imageSrc="/before.jpeg" title={cards[0].title} />
              <CardText>
                <CardHeader>
                  <CardType>{cards[0].service}</CardType>
                </CardHeader>
                <CardTitle>{cards[0].title}</CardTitle>
              </CardText>
            </Card>
          </CardColumn>
          <CardColumn>
            <Card>
              <CardImage imageSrc="/after.jpeg" title={cards[1].title} />
              <CardText>
                <CardHeader>
                  <CardType>{cards[1].service}</CardType>
                </CardHeader>
                <CardTitle>{cards[1].title}</CardTitle>
              </CardText>
            </Card>
          </CardColumn>
        </ThreeColumn>
      </Content>
    </Container>
  );
};

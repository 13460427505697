import React, { useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import emailjs from "@emailjs/browser";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
const Form = tw.form`flex flex-col`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`placeholder:text-white`;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full self-end sm:w-32 lg:w-56 mt-6 py-3 bg-gray-100 text-primary-700 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const Error = tw.p`py-2 px-4 bg-red-500 mt-2 rounded-md text-white font-semibold`;

export default () => {
  const form = useRef();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const formData = new FormData(form.current);
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const message = formData.get("message");
    const newErrors = {};

    if (!name || name.length < 2 || !/^[a-zA-Z\s]+$/.test(name)) {
      newErrors.name =
        "Name must be at least 2 characters and contain only letters and spaces.";
    }
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      newErrors.email = "Invalid email format.";
    if (
      phone &&
      !/^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/.test(phone)
    )
      newErrors.phone = "Phone number must be valid and 10 digits (USA).";
    if (!message || message.length < 5)
      newErrors.message = "Message must be at least 5 characters.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    emailjs
      .sendForm(
        "service_ovnv5kr",
        "template_6zm44ae",
        form.current,
        "SnRhfoOP8ljxh3H4l"
      )
      .then(
        () => {
          alert("Email sent successfully!");
        },
        (error) => {
          alert("FAILED...", error.text);
        }
      );
  };

  return (
    <Container id="quote">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Claim your FREE floor or carpet shampooing today before the offer expires!</h2>
            <Form ref={form} onSubmit={sendEmail}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      placeholder="E.g. John Doe"
                      required
                    />
                    {errors.name && <Error>{errors.name}</Error>}
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="E.g. john@mail.com"
                      required
                    />
                    {errors.email && <Error>{errors.email}</Error>}
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="phone-input">Your Phone (optional)</Label>
                    <Input
                      id="phone-input"
                      type="text"
                      name="phone"
                      placeholder="555-123-4567"
                    />
                    {errors.phone && <Error>{errors.phone}</Error>}
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="message-input">Location details</Label>
                    <TextArea
                      id="message-input"
                      name="message"
                      required
                      placeholder="E.g. Office, open to the public, 2 bathrooms..."
                    />
                    {errors.message && <Error>{errors.message}</Error>}
                  </InputContainer>
                </Column>
              </TwoColumn>
              <SubmitButton type="submit">Get the offer</SubmitButton>
            </Form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase from "components/headers/light.js";
import { SectionHeadingTop } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeadingTop
)`text-left text-primary-900 leading-tight text-4xl xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg xl:max-w-xl`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block md:w-72 tracking-wide text-center md:py-5`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-10 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16 flex flex-col-reverse lg:flex-col`;
const Testimonial = tw.div`order-1 sm:order-1 lg:order-2 max-w-sm rounded-b md:rounded-none relative sm:relative bottom-[-1rem] sm:top-[2rem] lg:top-0 lg:top-[-5rem] inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-gray-300 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const Image = tw.img`w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-primary-100 rounded xl:-mb-8 hidden lg:block lg:h-3/4`; 
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Quote = tw.blockquote`text-base text-gray-800 relative`;
const Highlight = tw.b`text-red-600 uppercase text-lg`;

export default ({
  heading = "Commercial cleaning services in New York & New Jersey.",
  description = "At Metropolitan Cleaning Services (MCS), we specialize in delivering top-notch commercial cleaning solutions tailored to your business. Whether you need office cleaning services, healthcare cleaning services, or even advanced electrostatic disinfection, our team ensures a healthier, cleaner workspace.",
  imageDecoratorBlob = true,
  primaryButtonUrl = "tel:+19085405092",
  buttonRounded = true,
  primaryButtonText = "Get a Free Quote Today",
  testimonial = {
    quote:
      " Enjoy free floor washing and shining or carpet shampooing during the first month of your annual contract. ",
    customerName: "John D., New York",
    customerCompany: "Delos Inc.",
  },
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <>
      <Header />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading style={{textAlign: "left"}}>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                {primaryButtonText}
              </PrimaryButton>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image rel="preload" title="Main image" src="/polishedFloor.jpg" alt="Old person smiling at camera, happy because the quality of Metropolitan Cleaning Services " />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <Quote><Highlight>Limited time offer:</Highlight> {testimonial.quote}
                    <a href="tel:+19085405092" style={{color: "red", fontWeight: "600"}}>Call now!</a>
                  </Quote>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};

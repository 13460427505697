import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import styled from "styled-components";

const Container = tw(
  ContainerBase
)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;

const CountdownContainer = tw.div`mt-8 text-center`;
const CountdownText = tw.div`text-2xl font-semibold text-gray-200`;
const CountdownValue = tw.div`text-5xl font-bold text-red-500`;

const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = targetDate - now;

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return null;
};

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "",
  heading = "Act Now: Get FREE Floor or Carpet Shampooing!",
  description = "Sign up for an annual contract today and enjoy complimentary floor washing, shining, or carpet shampooing during your first month (commercial cleaning only). This is a limited-time offer—don’t miss out!",
  primaryButtonText = "Get The Offer Now!",
  primaryButtonUrl = "tel:+19085405092",
  buttonRounded = true,
}) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    // Fecha objetivo: 31 de enero a las 11:59 PM
    const targetDate = new Date("2025-01-31T23:59:59");

    const timer = setInterval(() => {
      const remainingTime = calculateTimeLeft(targetDate);
      if (remainingTime) {
        setTimeLeft(remainingTime);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>

        <CountdownContainer>
          <CountdownText>Offer ends in:</CountdownText>
          {timeLeft && (
            <CountdownValue>
              {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
            </CountdownValue>
          )}
          <PrimaryButton
            buttonRounded={buttonRounded}
            as="a"
            href={primaryButtonUrl}
          >
            {primaryButtonText}
          </PrimaryButton>
        </CountdownContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
